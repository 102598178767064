import {Component, EventEmitter, Input, Output} from '@angular/core';
import {isBiggerThanOneGigabyte} from '../../../helpers/common/generic-utils';
import {DmsElement} from '../../../models/dms-element';
import {DocumentTypeEnum} from '../../../enums/document-type.enum';
import {isNullOrUndefined} from 'util';
import {SphereService} from '../../../services/sphere.service';
import {
  piDelete,
  piDocumentale,
  piDocumentPage,
  piDownload,
  piInfo,
  piShare,
  piView,
  PwcIconsLibrary
} from "@pwc/icons";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents-toolbar',
  templateUrl: './documents-toolbar.component.html',
  styleUrls: ['./documents-toolbar.component.scss']
})
export class DocumentsToolbarComponent {
  @Input() totalElements: number;
  @Input() selectedElements: DmsElement[] = [];
  @Input() isMoving: boolean;
  @Input() isSharing: boolean;
  @Input() isConnecting: boolean;
  @Input() isAttaching: boolean;
  @Input() selected: number;
  @Input() selectedDimension: number;
  @Input() total: number;
  @Input() totalGlobal: number;
  @Input() hasFolders: boolean;
  @Input() onlyOneFileSelected: boolean;
  @Input() canDownloadDossier: boolean;
  @Output() allSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() allGlobalSelectedForExport: EventEmitter<void> = new EventEmitter<void>();
  @Output() deselectAllElements: EventEmitter<void> = new EventEmitter<void>();
  @Output() download: EventEmitter<void> = new EventEmitter<void>();
  @Output() downloadDossier: EventEmitter<void> = new EventEmitter<void>();
  @Output() share: EventEmitter<void> = new EventEmitter<void>();
  @Output() view: EventEmitter<void> = new EventEmitter<void>();
  @Output() move: EventEmitter<void> = new EventEmitter<void>();
  @Output() connect: EventEmitter<void> = new EventEmitter<void>();
  @Output() attach: EventEmitter<void> = new EventEmitter<void>();
  @Output() tag: EventEmitter<void> = new EventEmitter<void>();
  @Output() favorite: EventEmitter<void> = new EventEmitter<void>();
  @Output() export: EventEmitter<void> = new EventEmitter<void>();
  @Output() lawConservation: EventEmitter<void> = new EventEmitter<void>();
  @Output() noLawConservation: EventEmitter<void> = new EventEmitter<void>();
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
  @Output() newFileVersion: EventEmitter<void> = new EventEmitter<void>();
  @Output() exportCSV: EventEmitter<void> = new EventEmitter<void>();

  public showConservation : boolean = true;

  constructor(private sphereService: SphereService,
              private iconsLibrary: PwcIconsLibrary) {
    iconsLibrary.registerIcons([
      piDocumentale,
      piDocumentPage,
      piInfo,
      piView,
      piDelete,
      piDownload,
      piShare,
    ]);
    this.showConservation = environment.showConservation;
  }

  selectAllRows() {
    this.allSelected.emit(true);
  }

  //select all elements of all pages for export
  selectAllGlobalForExport() {
    this.allGlobalSelectedForExport.emit();
  }

  //deselct all elements
  deselectAll() {
    this.deselectAllElements.emit();
  }

  onDownload() {
    this.download.emit();
  }

  onDownloadDossier() {
    this.downloadDossier.emit();
  }

  onShare() {
    this.share.emit();
  }

  onView() {
    this.view.emit();
  }

  onMove() {
    this.move.emit();
  }

  onTag() {
    this.tag.emit();
  }

  onConnect() {
    this.connect.emit();
  }

  onAttach() {
    this.attach.emit();
  }

  onFavorite() {
    this.favorite.emit();
  }

  onExport() {
    this.export.emit();
  }

  onLawConservation() {
    this.lawConservation.emit();
  }

  onNoLawConservation() {
    this.noLawConservation.emit();
  }

  onDelete() {
    this.delete.emit();
  }

  onNewFileVersion() {
    this.newFileVersion.emit();
  }

  checkButtonEnableByDimension(): boolean {
    return isBiggerThanOneGigabyte(this.selectedDimension);
  }

  onExportCSV() {
    this.exportCSV.emit();
  }

  get isHybridSelection(): boolean {
    let files = 0;
    let folders = 0;

    for (const element of this.selectedElements) {
      switch (element.documentType) {
        case DocumentTypeEnum.FILE:
          files++;
          break;
        case DocumentTypeEnum.FOLDER:
          folders++;
          break;
      }
      if ((files > 0 && folders > 0) || folders > 1) {
        return true;
      }
    }

    return false;
  }

  removeEye(): boolean {
    for (const element of this.selectedElements) {
      if (element.name.includes(".zip") ||element.name.includes(".p7m")) {
        return true
      }
    }
    return false;
  }

  get isFileSelection(): boolean {
    for (const element of this.selectedElements) {
      if (element.documentType === DocumentTypeEnum.FOLDER) {
        return false;
      }
    }

    return true;
  }

  get isFolderSelection(): boolean {
    for (const element of this.selectedElements) {
      if (element.documentType === DocumentTypeEnum.FILE) {
        return false;
      }
    }

    return true;
  }

  get canDownload(): boolean {
    if (this.isMovingOrSharing || this.isConnecting || this.isAttaching) {
      return false;
    }
    if (this.sphereService.isOwned()) {
      return true;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionDownload
    ), true);
  }

  get canUploadFile(): boolean {
    if (this.isMovingOrSharing || this.isConnecting || this.isAttaching) {
      return false;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !curr.isParentAZipFileLawConserved
      && (this.sphereService.isOwned()
        || (!isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionUpload)
      )
    ), false);
  }

  get canMove(): boolean {
    if (this.isConnecting || this.isSharing || this.isAttaching) {
      return false;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !curr.isParentAZipFileLawConserved
      && (this.sphereService.isOwned() ||
        (!isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionUpload)
      )
    ), true);
  }

  get canTag(): boolean {
    if (this.isMovingOrSharing || this.isConnecting || this.isAttaching) {
      return false;
    }
    if (this.sphereService.isOwned()) {
      return true;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionRead
    ), true);
  }

  getFavoritePermission(): boolean {
    return !this.isMovingOrSharing && !this.isConnecting;
  }

  get canDelete(): boolean {
    if (this.isMovingOrSharing || this.isConnecting || this.isAttaching) {
      return false;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !curr.isParentAZipFileLawConserved
      && (this.sphereService.isOwned() || (!isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionDelete))
    ), true);
  }

  get canShare(): boolean {
    if (this.isConnecting || this.isMoving || this.isAttaching) {
      return false;
    }

    if (this.sphereService.isOwned()) {
      return true;
    }
    // FIXME: delete the following check to enable for re-sharing.
    if (this.sphereService.isShared()) {
      return false;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionReSharing
    ), true);
  }

  get canExportCSV(): boolean {
    return !(this.isConnecting || this.isMovingOrSharing || this.isAttaching);
  }

  get canConnect(): boolean {
    if (this.isMovingOrSharing || this.isAttaching) {
      return false;
    }

    if (!this.isFileSelection) {
      return false;
    }

    if (this.sphereService.isOwned()) {
      return true;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionUpload
    ), true);
  }

  get canAttach(): boolean {

    if (this.selectedElements.length > 1) {
      return false;
    }

    if (this.isMovingOrSharing || this.isConnecting) {
      return false;
    }

    if (!this.isFileSelection) {
      return false;
    }

    if (this.sphereService.isOwned()) {
      return true;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionUpload
    ), true);
  }

  get isMovingOrSharing(): boolean {
    return this.isMoving || this.isSharing;
  }

  getLawConservationPermission(): boolean {
    if (this.isMovingOrSharing || this.isConnecting || this.isAttaching) {
      return false;
    }
    if (this.sphereService.isOwned()) {
      return true;
    }

    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      !isNullOrUndefined(curr.dmsSharing) && curr.dmsSharing.permissionLawConservationAllowed
    ), true);
  }

  hideNoLawConservation(): boolean {
    if (this.isMovingOrSharing || this.isConnecting || this.isAttaching) {
      return true;
    }

    return this.selectedElements.reduce((value: boolean, element: DmsElement) => (
      value || element.lawConservation
      || element.lawConservationAtLeastOneVersion
      || element.excludeParentFromLawConservation
      // || element.configurationIdParent === null
      || (!isNullOrUndefined(element.dmsSharing) && !element.dmsSharing.permissionLawConservationAllowed)
    ), false);
  }

  isLawConserved(): boolean {
    return this.selectedElements.reduce((conserved: boolean, element: DmsElement) => conserved || element.lawConservation || element.lawConservationAtLeastOneVersion, false);
  }

  isParentAZipFile(): boolean {
    return this.selectedElements.reduce((prev: boolean, curr: DmsElement) => (
      curr.isParentAZipFile
    ), true);
  }
}
