import {SelectableModel} from './common/base/selectable-model';
import {Sphere} from './sphere';
import {DocumentTypeEnum} from '../enums/document-type.enum';
import {DmsElementMetadata} from './dms-element-metadata';
import {DmsFileHistoryResource} from './api/dms-file-history-resource';
import {DmsElementInfoResource} from './api/documents/dms-element-info-resource';
import {DmsSharingResource} from './api/share/dms-sharing-resource';
import {DmsTagResource} from './api/tag/dms-tag-resource';

class DmsTagRagResource {
}

export class DmsElement extends SelectableModel {
  id: number;
  name: string;
  parent?: DmsElement;
  parentId: number;
  sphere?: Sphere;
  sphereId: number;
  fiscalYear?: number; // Year of fiscal year.
  documentType: DocumentTypeEnum;
  customFolder: boolean;
  canCreateFolder: boolean;

  lawConservation: boolean;
  lawConservationAtLeastOneVersion: boolean;
  lawConservatioDate: Date;
  lawConservatioToken: string;
  lawConservatioFilename: string;
  lawConservatioRectification: boolean;
  lawConservatioNote: string;

  lawConservationRunning: boolean;
  excludeFromLawConservation: boolean;
  excludeParentFromLawConservation: boolean;
  lastLawConservationExecution: Date;
  lawConservationError: string;
  lawConservationErrorCode: string;
  lawConservationErrorDate: Date;
  configurationId?: number;
  configurationIdParent?: number;

  isParentAZipFile: boolean;
  isParentAZipFileLawConserved: boolean;

  metadata?: DmsElementMetadata[] = [];
  metadataComplete?: boolean;
  crossYear?: boolean;
  extension: string;
  fiscalStartDate: number;
  fiscalEndDate: number;
  documentDate: number;
  fileHistorySelected?: DmsFileHistoryResource;
  isShared: boolean = false;
  sharingIds?: number[] = [];
  dmsSharing?: DmsSharingResource;
  // element2Tags?: DmsTagResource[];
  path?: string;
  createdDate?: number;
  elementInfo: DmsElementInfoResource[] = [];
  elementInfoLawConservation?: any [] = []


  favorite: boolean;
  shortcut: boolean;
}
