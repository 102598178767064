import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ShareState } from '../../enums/share/share-state.enum';
import { FiscalYearResource } from '../../models/api/fiscal-year-resource';
import { DmsSharingEntityResource } from '../../models/api/share/dms-sharing-entity-resource';
import { DmsElement } from '../../models/dms-element';
import { DmsSharing } from '../../models/share/dms-sharing';
import { DmsSharingElement } from '../../models/share/dms-sharing-element';
import { DmsSharingEntity } from '../../models/share/dms-sharing-entity';
import { Sphere } from '../../models/sphere';
import { DmsCartService } from '../../services/dms-cart.service';
import { SharingElementsService } from '../../services/share/sharing-elements.service';
import { SphereService } from '../../services/sphere.service';
import { ButtonVariant } from '../common/buttons/enums/button-variant.enum';
import { ModalComponent } from '../common/modals/modal/modal.component';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent {
  private readonly steps = {
    elements: 1,
    share: 2,
    'share-type': 3
  };
  readonly STEP_ELEMENTS = 'elements';
  readonly STEP_SHARE = 'share';
  readonly STEP_SHARE_TYPE = 'share-type';

  currentStep: string = this.STEP_ELEMENTS;
  title: string;
  sharing: DmsSharing = new DmsSharing();
  submitted: boolean = false;

  @Input() elements: DmsSharingEntity[] = [];
  @Input() selectedYear: FiscalYearResource;
  @Input() canEdit: boolean = true;
  @Output() deselect: EventEmitter<DmsElement> = new EventEmitter<DmsElement>();
  @Output() remove: EventEmitter<{ sharingId: number; elementId: number }> = new EventEmitter<{ sharingId: number; elementId: number }>();
  @Output() shared: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('documentShareModal') documentShareModal: ModalComponent;
  @ViewChild('deleteSharingConfirmation') deleteSharingConfirmation: ModalComponent;
  @ViewChild('nav') nav: NgbNav;

  sharingId: number;
  deletedElementIds: number[] = [];

  btnSuccess = ButtonVariant.SUCCESS;

  constructor(private sharingElementsService: SharingElementsService,
    private sphereService: SphereService,
    private dmsCartService: DmsCartService,
    private toast: ToastrService,
    private translateSrv: TranslateService) {
  }

  openModal(sharingId?: number): void {
    this.sharingId = sharingId;
    this.deletedElementIds = [];
    this.submitted = false;

    if (!isNullOrUndefined(this.sharingId)) {
      this.title = this.translateSrv.instant('share.modal.update-share');
      this.sharingElementsService.loadDetail(this.sharingId, true)
        .subscribe((value: DmsSharing) => {
          this.sharing = value;
          this.elements = this.sharingElementsService.convertSharingElementsListToSharingEntities(this.sharing.elements);
        });
    } else {
      this.title = this.translateSrv.instant('generic.action.generate-sharing');
    }

    this.documentShareModal.open();
  }

  cancel(): void {
    // Reset sharing instance.
    this.navigateStep(this.STEP_ELEMENTS);
    this.sharing = new DmsSharing();
    this.sharingId = null;
    this.submitted = false;
    this.deletedElementIds = [];
    this.elements = [];
    this.documentShareModal.close();
  }

  back(): void {
    if (this.currentStep === this.STEP_SHARE) {
      this.navigateStep(this.STEP_ELEMENTS);
    } else if (this.currentStep === this.STEP_SHARE_TYPE) {
      this.navigateStep(this.STEP_SHARE);
    }
  }

  next(): void {
    if (this.currentStep === this.STEP_ELEMENTS) {
      this.navigateStep(this.STEP_SHARE);
    } else if (this.currentStep === this.STEP_SHARE) {
      this.navigateStep(this.STEP_SHARE_TYPE);
    }
  }

  navigateStep(step: string): void {
    this.currentStep = step;

    // This allows to select a tab after it has been dynamically enabled.
    setTimeout(() => {
      this.nav.select(step);
    }, 1);
  }

  onTabChange(event: NgbNavChangeEvent): void {
    if (this.currentStep !== event.nextId) {
      this.currentStep = event.nextId;
    }
  }

  isDisabled(step: string): boolean {
    return isNullOrUndefined(this.sharingId) && this.steps[step] > this.steps[this.currentStep];
  }

  onRemove(entity: DmsSharingEntity) {
    if (!isNullOrUndefined(this.sharingId)) {
      this.deletedElementIds.push(entity.elementId);
      this.elements = this.elements.filter((elem: DmsSharingEntity) => !(elem.elementId == entity.elementId && elem.fiscalYear == entity.fiscalYear));
      // const elements = this.sharing.elements.filter((element: DmsSharingElement) => this.deletedElementIds.indexOf(element.elementId) < 0);
      // if (elements.length > 0) {
      //   this.elements = this.sharingElementsService.convertSharingElementsListToSharingEntities(elements);
      // }
    } else {
      this.deselect.emit(entity.element);
    }
  }

  startDelete() {
    this.deleteSharingConfirmation.open();
  }

  delete() {
    this.sharingElementsService.delete(this.sharingId)
      .subscribe(() => {
        this.deleted.emit();
        this.cancel();
      });
  }

  submit() {
    if (this.submitted) {
      console.warn('Form already submitted');
      return;
    }

    if (!this.isValid) {
      console.warn('Invalid data!');
      return;
    }

    this.submitted = true;

    const resource = this.sharingElementsService.convertModelToResource(this.sharing);
    resource.shareState = ShareState.FINAL_STEP;

    resource.sharingEntryList = [];
    resource.fiscalYears = [];
    for (const element of this.elements) {
      const sharingEntity = new DmsSharingEntityResource();
      sharingEntity.elementId = element.elementId;
      sharingEntity.fiscalYearId = element.fiscalYearId;
      sharingEntity.fiscalYear = element.fiscalYear;
      resource.sharingEntryList.push(sharingEntity);
      resource.fiscalYears.push(element.fiscalYear);
    }

    resource.userEmails = [];
    for (const user of this.sharing.users) {
      resource.userEmails.push(user.email);
    }

    this.sphereService.currentSphere.pipe(
      first(),
      switchMap((sphere: Sphere) => {
        resource.sphereId = sphere.id;
        if (isNullOrUndefined(this.sharingId)) {
          return this.sharingElementsService.insert(resource);
        } else {
          return this.sharingElementsService.update(resource);
        }
      })
    ).subscribe((res: DmsSharing) => {
      this.sharing = res;
      this.shared.emit();
      this.cancel();
      this.dmsCartService.clear();
    });
  }

  get isValid(): boolean {
    return this.isElementsValid && this.isShareValid && this.isShareTypeValid;
  }

  get isCurrentStepValid(): boolean {
    switch (this.currentStep) {
      case this.STEP_ELEMENTS:
        return this.isElementsValid;
      case this.STEP_SHARE:
        return this.isShareValid;
      case this.STEP_SHARE_TYPE:
        return this.isShareTypeValid;
    }
  }

  get isElementsValid(): boolean {
    // if (!isNullOrUndefined(this.sharing) && this.sharing.elements.length > 0) {
    //   this.elements = this.sharingElementsService.convertElementsListToSharingEntities(this.sharing.elements);
    // }
    if (!isNullOrUndefined(this.elements)) {
      return this.elements.length > 0;
    } else {
      return false;
    }
  }

  get isShareValid(): boolean {
    // Name is required.
    if (isNullOrUndefined(this.sharing.name) || !this.sharing.name.trim().length) {
      return false;
    }

    // At least one of validityStartDate and validityEndDate is required if not alwaysValid.
    if (!this.sharing.alwaysValid && (isNullOrUndefined(this.sharing.validityStartDate) || isNullOrUndefined(this.sharing.validityEndDate))) {
      return false;
    }

    // At least one user should be selected.
    if (!this.sharing.users.length) {
      return false;
    }

    return true;
  }

  get isShareTypeValid(): boolean {
    // At least read permission should be selected.
    return this.sharing.permissionRead;
  }

  get isNew(): boolean {
    return isNullOrUndefined(this.sharingId);
  }

  get isEditEnabled(): boolean {
    return this.canEdit && (this.isNew || this.sharing.flagValid);
  }
}
