<pwc-modal #documentDetailModal id="document-detail" (closed)="onClose($event)">
    <div modal-title>
        <img src="{{iconPath}}" [alt]="fileName" style="width: 40px;height: 40px; padding-right: 20px;" *ngIf="fileLoaded">{{fileName}}
    </div>

    <div modal-body>
        <div class="row">
            <div class="col-md-6">
                <div class="preview-area d-flex align-items-center justify-content-center" *ngIf="!loading && !fileLoaded">
                    <fa-icon [icon]="faCircleNotch" [spin]="true" size="2x"></fa-icon>
                </div>
                <div class="preview-area" *ngIf="fileLoaded" [ngClass]="{'d-flex align-items-center justify-content-center': !canLoadFile}">
                    <pdf-viewer #pdfViewer [src]="previewReportUrl" [original-size]="false" [render-text]="true" style="display: block;"></pdf-viewer>
                    <div class="text-md-center" *ngIf="!canLoadFile">
                        {{'documents.detail.preview-not-available'|translate}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="file-metadata-wrapper">
                    <dl class="row" *ngIf="dmsElement">
                        <dt class="col-sm-12 metadata-label-error" *ngIf="errorCrossYear">{{errorCrossYear}}</dt>
                        <dt class="col-sm-4 metadata-label mandatory">{{'documents.detail.file-name'|translate}}</dt>
                        <dd class="col-sm-8 metadata-value">
                            <div class="form-group form-input-group">
                                <input type="text" class="form-control form-input no-shadow" [(ngModel)]="fileName" [disabled]="!metadataEditable()">
                            </div>
                            <div class="form-group form-input-group" *ngIf="!isFilenameValid(fileName)">
                                <label class="text-danger p-3">{{'documents.detail.file-name-error'|translate}}</label>
                            </div>
                        </dd>
                        <dt class="col-sm-4 metadata-label mandatory">{{'documents.detail.document-date'|translate}}
                        </dt>
                        <dd class="col-sm-8 metadata-value">
                            <div class="form-group form-input-group withError">
                                <input type="text" class="form-control form-input mandatory no-shadow mr-2" placeholder="DD/MM/YYYY" (ngModelChange)="setNgbDocumentDate($event)" [ngModel]="documentDate" placement="bottom-left" [disabled]="!metadataEditable()" ngbDatepicker #d0="ngbDatepicker">
                                <div (click)="d0.toggle()" class="form-input-icon append pointer w-auto" *ngIf="metadataEditable()">
                                    <pwc-icon name="calendar_small"></pwc-icon>
                                </div>
                                <div *ngIf="errorDocumentDate" class="invalid-feedback" style="padding-top: 5px">
                                    {{errorDocumentDate}}
                                </div>
                            </div>
                        </dd>
                        <dt class="col-sm-4 metadata-label mandatory">{{'generic.field.start-date'|translate}}</dt>
                        <dd class="col-sm-8 metadata-value">
                            <div class="form-group form-input-group withError">
                                <input type="text" class="form-control form-input mandatory no-shadow mr-2" placeholder="DD/MM/YYYY" (ngModelChange)="setNgbFiscalStartDate($event)" [ngModel]="startDate" placement="bottom-left" [disabled]="!metadataEditable()" ngbDatepicker #d1="ngbDatepicker">
                                <div (click)="d1.toggle()" class="form-input-icon append pointer w-auto" *ngIf="metadataEditable()">
                                    <pwc-icon name="calendar_small"></pwc-icon>
                                </div>
                                <div *ngIf="errorDate" class="invalid-feedback" style="padding-top: 5px">{{errorDate}}
                                </div>
                            </div>
                        </dd>
                        <dt class="col-sm-4 metadata-label mandatory">{{'generic.field.end-date'|translate}}</dt>
                        <dd class="col-sm-8 metadata-value">
                            <div class="form-group form-input-group withError">
                                <input type="text" class="form-control form-input mandatory no-shadow" placeholder="DD/MM/YYYY" (ngModelChange)="setNgbFiscalEndDate($event)" [ngModel]="endDate" ngbDatepicker placement="bottom-left" #d2="ngbDatepicker" [disabled]="!metadataEditable()">
                                <div (click)="d2.toggle()" class="form-input-icon append pointer w-auto" *ngIf="metadataEditable()">
                                    <pwc-icon name="calendar_small"></pwc-icon>
                                </div>
                                <div *ngIf="errorEndDate" class="invalid-feedback" style="padding-top: 5px">
                                    {{errorEndDate}}</div>
                            </div>
                        </dd>
                        <ng-container *ngIf="superUserComments && generalCounselDmsSphere">
                            <dt class="col-sm-12">
                                <app-comments [commentList]="commentList" [fileId]="dmsElement.id"></app-comments>
                            </dt>
                        </ng-container>
                        <!--<ng-container *ngFor="let meta of dmsElement.metadata; let i=index">-->
                        <ng-container *ngFor="let meta of sortMetadata(); let i=index">


                            <!--              <dt *ngIf="! meta.elementMetadataReg.code.startsWith('soggetto_', 0)" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">{{meta.elementMetadataReg?.name}} </dt>-->
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject1selectedRole('PF') && valoriSoggetto1PF.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject1selectedRole('PG') && valoriSoggetto1PG.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject1selectedRole('PAI') && valoriSoggetto1PAI.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject1selectedRole('PAE') && valoriSoggetto1PAE.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject2selectedRole('PF') && valoriSoggetto2PF.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject2selectedRole('PG') && valoriSoggetto2PG.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject2selectedRole('PAI') && valoriSoggetto2PAI.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && getSubject2selectedRole('PAE') && valoriSoggetto2PAE.includes(meta.elementMetadataReg.code) && !meta.elementMetadataReg?.explicitNotVisible" class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <dt *ngIf="meta && meta.elementMetadataReg && ! valoriSoggetto1PF.includes(meta.elementMetadataReg.code) &&
                                ! valoriSoggetto1PG.includes(meta.elementMetadataReg.code) &&
                                ! valoriSoggetto1PAI.includes(meta.elementMetadataReg.code) &&
                                ! valoriSoggetto1PAE.includes(meta.elementMetadataReg.code) &&
                                ! valoriSoggetto2PF.includes(meta.elementMetadataReg.code) &&
                                ! valoriSoggetto2PG.includes(meta.elementMetadataReg.code) &&
                                ! valoriSoggetto2PAI.includes(meta.elementMetadataReg.code) &&
                                ! valoriSoggetto2PAE.includes(meta.elementMetadataReg.code)  &&
                                ! meta.elementMetadataReg?.explicitNotVisible
                                 " class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg?.required}">
                                {{meta.elementMetadataReg?.name}}</dt>
                            <!--              <dt class="col-sm-4 metadata-label" [ngClass]="{'mandatory': meta.elementMetadataReg.required}">{{meta.elementMetadataReg?.name}}</dt>-->
                            <div *ngIf="meta && meta.elementMetadataReg" class="col-sm-8 metadata-value">
                                <div *ngIf="meta.elementMetadataReg.code !== 'modform_s' &&
                                    meta.elementMetadataReg.code !== 'soggetto_1_ruolo_s' &&
                                    meta.elementMetadataReg.code !== 'soggetto_1_tipo_s' &&
                                    meta.elementMetadataReg.code !== 'soggetto_2_ruolo_s' &&
                                    meta.elementMetadataReg.code !== 'soggetto_2_tipo_s' &&
                                    !meta.elementMetadataReg?.explicitNotVisible">
                                    <div class="form-group form-input-group" *ngIf="isStringMetadata(meta.elementMetadataReg.type)
                                      && ! valoriSoggetto1PF.includes(meta.elementMetadataReg.code)
                                      && ! valoriSoggetto1PG.includes(meta.elementMetadataReg.code)
                                      && ! valoriSoggetto1PAI.includes(meta.elementMetadataReg.code)
                                      && ! valoriSoggetto1PAE.includes(meta.elementMetadataReg.code)
                                      && ! valoriSoggetto2PF.includes(meta.elementMetadataReg.code)
                                      && ! valoriSoggetto2PG.includes(meta.elementMetadataReg.code)
                                      && ! valoriSoggetto2PAI.includes(meta.elementMetadataReg.code)
                                      && ! valoriSoggetto2PAE.includes(meta.elementMetadataReg.code)">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <!-- Generic Numeric Metadata -->
                                    <div class="form-group form-input-group" *ngIf="isNumberMetadata(meta.elementMetadataReg.type)">
                                        <input type="number" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="number" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">

                                    </div>
                                    <!-- Generic Boolean metadata -->
                                    <div class="form-group form-input-group" *ngIf="isBooleanMetadata(meta.elementMetadataReg.type) && meta.elementMetadataReg.code != 'cop_b'" style="vertical-align: middle;">
                                        <input type="checkbox" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <div class="row" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required">
                                            <div class="col-sm-1">
                                                <input type="checkbox" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" [disabled]="!metadataEditable()">
                                            </div>
                                            <div class="col-sm-11">
                                                <span class="invalid-feedback" style="padding-top: 5px">
                                                    ({{'documents.detail.to-tick'|translate}})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- cop_b Boolean metadata -->
                                    <div class="form-group form-input-group" *ngIf="isBooleanMetadata(meta.elementMetadataReg.type) && meta.elementMetadataReg.code == 'cop_b'" style="vertical-align: middle;">
                                        <input type="checkbox" class="form-control form-input no-shadow" [(ngModel)]="cop_b" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <div class="row" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required">
                                            <div class="col-sm-1">
                                                <input type="checkbox" (change)="onChangeCheckBoxvalue()" class="form-control form-input mandatory no-shadow" [(ngModel)]="cop_b" [disabled]="!metadataEditable()">
                                            </div>
                                            <div class="col-sm-11">
                                                <span class="invalid-feedback" style="padding-top: 5px">
                                                    ({{'documents.detail.to-tick'|translate}})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group form-input-group withError" *ngIf="isDateMetadata(meta.elementMetadataReg.type)">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [ngClass]="{mandatory: meta.elementMetadataReg?.required}" placeholder="DD/MM/YYYY" (ngModelChange)="setNgbMetadata($event,meta.id)" [ngModel]="getDatePickerValue(meta)" ngbDatepicker
                                            #i="ngbDatepicker" [disabled]="!metadataEditable()">
                                        <div (click)="i.toggle()" class="form-input-icon append pointer w-auto" *ngIf="metadataEditable()">
                                            <pwc-icon name="calendar_small"></pwc-icon>
                                        </div>
                                        <div *ngIf="meta && meta.elementMetadataReg && meta.error" class="invalid-feedback" style="padding-top: 5px">
                                            {{'attachment.metadata.invalid-format'|translate}}
                                        </div>
                                    </div>
                                </div>
                                <!--CONTROLLO SUL TIPO -->
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'modform_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <!--CONTROLLO SU MOD FORMAZIONE -->
                                    <div class="form-group form-input-group">
                                        <!--                    <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value"-->
                                        <!--                           *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">-->
                                        <ng-select bindLabel="code" [ngClass]="{'mandatory': meta.elementMetadataReg?.required}" class="mr-0" [placeholder]="'attachment.modal.select-mode'|translate" [clearable]="true" [multiple]="false" [(ngModel)]="meta.value" (change)="suggestCopBMeta(meta.value)">
                                            <ng-option [value]="val.code" *ngFor="let val of modFormazioneList">
                                                {{val.label | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_ruolo_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <div class="form-group form-input-group">
                                        <ng-select bindLabel="code" [ngClass]="{'mandatory': meta.elementMetadataReg?.required}" class="mr-0" [placeholder]="'attachment.modal.select-subject-1-role'|translate" [clearable]="true" [multiple]="false" [(ngModel)]="meta.value">
                                            <ng-option [value]="val.code" *ngFor="let val of ruoloSoggetto1">
                                                <!-- {{logger(val)}} -->
                                                {{val.label | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_tipo_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <div class="form-group form-input-group">
                                        <ng-select bindLabel="code" (change)="changeFn1(meta)" [ngClass]="{'mandatory': meta.elementMetadataReg?.required}" class="mr-0" [placeholder]="'attachment.modal.select-subject-1-type'|translate" [clearable]="true" [multiple]="false" [(ngModel)]="meta.value">
                                            <ng-option [value]="val.code" *ngFor="let val of tipoSoggetto">
                                                {{val.label | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <!-- Subject1SelectedRole == PF -->
                                <div *ngIf="getSubject1selectedRole('PF')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_cog_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_nom_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_cf_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <div *ngIf="getSubject1selectedRole('PG')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_org_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_piva_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_denuff_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <div *ngIf="getSubject1selectedRole('PAI')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_nomamm_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_ipaaoocode_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_ipauorcode_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <div *ngIf="getSubject1selectedRole('PAE')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_nomamm_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_denuff_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_1_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_ruolo_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <div class="form-group form-input-group">
                                        <ng-select bindLabel="code" [ngClass]="{'mandatory': meta.elementMetadataReg.required}" class="mr-0" [clearable]="false" [multiple]="false" [(ngModel)]="meta.value">
                                            <ng-option [value]="val.code" *ngFor="let val of ruoloSoggetto2">
                                                {{val.label | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_tipo_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <div class="form-group form-input-group">
                                        <ng-select bindLabel="code" (change)="changeFn2(meta)" [ngClass]="{'mandatory': meta.elementMetadataReg.required}" class="mr-0" [placeholder]="'attachment.modal.select-subject-2-type'|translate" [clearable]="true" [multiple]="false" [(ngModel)]="meta.value">
                                            <ng-option [value]="val.code" *ngFor="let val of tipoSoggetto">
                                                {{val.label | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <!-- Subject2selectedRole PG -->

                                <div *ngIf="getSubject2selectedRole('PF')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_cog_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_nom_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_cf_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <!-- Subject2selectedRole PG -->
                                <div *ngIf="getSubject2selectedRole('PG')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_org_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_piva_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_denuff_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <!-- Subject2selectedRole PAI -->
                                <div *ngIf="getSubject2selectedRole('PAI')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_nomamm_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_ipaaoocode_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_ipauorcode_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <!-- Subject2selectedRole ==PAE -->
                                <div *ngIf="getSubject2selectedRole('PAE')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_nomamm_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_denuff_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_2_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_3_ruolo_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <div class="form-group form-input-group">
                                        <ng-select bindLabel="code" [ngClass]="{'mandatory': meta.elementMetadataReg.required}" class="mr-0" [clearable]="false" [multiple]="false" [(ngModel)]="meta.value">
                                            <ng-option [value]="val.code" *ngFor="let val of ruoloSoggetto3">
                                                {{val.label | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_3_tipo_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <div class="form-group form-input-group">
                                        <ng-select bindLabel="code" (change)="changeFn3(meta.value)" [ngClass]="{'mandatory': meta.elementMetadataReg.required}" class="mr-0" placeholder="Seleziona la tipologia per il soggetto 3" [clearable]="true" [multiple]="false" [(ngModel)]="meta.value">
                                            <ng-option [value]="val.code" *ngFor="let val of tipoSoggetto">
                                                {{val.label | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div *ngIf="getSubject3selectedRole('PF')">
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_3_cog_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>
                                    <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_3_nom_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                        <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                        <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    </div>

                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_3_cf_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                </div>
                                <div *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.code == 'soggetto_3_inddig_s' && !meta.elementMetadataReg?.explicitNotVisible">
                                    <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value" *ngIf="!meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                    <input type="text" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value" *ngIf="meta && meta.elementMetadataReg && meta.elementMetadataReg.required" [disabled]="!metadataEditable()">
                                </div>
                            </div>
                            <div class="col-12"></div>

                        </ng-container>
                    </dl>
                </div>
            </div>
        </div>
    </div>

    <div modal-footer class="d-flex justify-content-between" style="width: 100%">
        <div>
            <button class="btn btn-ghost ml-auto align-self-end text-nowrap" style="min-width: 200px" (click)="uploadNewVersion()" *ngIf="canUploadNewVersion && !isAlreadyLawConservation()">
                <img src="/assets/images/icons/documents/new_document.svg"
                    [alt]="'documents.detail.new-version'|translate" class="btn-ghost-icon">
                <span class="d-inline-block">
                    {{'documents.detail.new-version'|translate}}
                </span>
            </button>
            <!-- <button class="btn btn-ghost ml-auto align-self-end text-nowrap dropdown-toggle" type="button" aria-expanded="false" mdbDropdownToggle style="min-width: 200px" (click)="documentRectification()" *ngIf="enableChanges() && isAlreadyLawConservation() && !dmsElement.isParentAZipFileLawConserved">
                <img src="/assets/images/icons/documents/new_document.svg" alt="Rettifica conservato" class="btn-ghost-icon">
                <span class="d-inline-block">
                {{'documents.detail.rectify-conserved'|translate}}
                </span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a class="dropdown-item" onclick="documentRectification()">Rettifica solo metadati</a></li>
                <li><a class="dropdown-item" href="#">Carica nuova versione e/o rettifica metadati</a></li>
            </ul> -->
        </div>
        <div class="text-md-center" *ngIf="fileIds.length > 1">
            <pwc-pagination [page]="position + 1" [pageSize]="1" [collectionSize]="fileIds.length" (pageChange)="onPageChange($event)"></pwc-pagination>
        </div>
        <div class="text-md-right">
            <button type="button" class="btn btn-undo" (click)="forceClose()">{{'generic.action.cancel'|translate}}</button>
            <ng-container *ngIf="superUserDocActions && generalCounselDmsSphere">
                <button type="button" class="btn btn-outline-primary ml-2" (click)="confirmWatermarkModal.open()" translate>
                    {{'documents.detail.watermark'|translate}}
                </button>
            </ng-container>
            <ng-container *ngIf="superUserDocActions && generalCounselDmsSphere">
                <button type="button" class="btn btn-outline-info ml-2" (click)="signComponent.signModal.open()" translate>
                    {{'documents.detail.signature'|translate}}
                </button>
            </ng-container>
            <button type="button" class="btn btn-success ml-2" (click)="save()" *ngIf="metadataEditable()" [disabled]="disableSave()">
                {{'generic.action.save'|translate}}
            </button>
            <button type="button" class="btn btn-success ml-2" (click)="saveAndNext()" [disabled]="disableSave()" *ngIf="metadataEditable() && fileIds.length > 1">
                {{'generic.action.save-next'|translate}}
            </button>
        </div>
    </div>

</pwc-modal>

<app-sign-document #signComponent></app-sign-document>

<pwc-confirmation-modal #confirmWatermarkModal id="confirmWatermarkModal" [title]="'documents.detail.watermark'|translate" [message]="'documents.detail.watermark-confirm'|translate" [positiveButtonText]="'generic.action.confirm'|translate" [negativeButtonText]="'generic.action.cancel'|translate"
    (positiveButtonClick)="onWatermarkConfirmed()">

    <pwc-spinner [name]="documentDetailSpinner" [fullscreen]="true"></pwc-spinner>