import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FileUploader } from '../../../modules/file-upload/file-uploader.class';
import { UploaderService } from '../../../services/uploader.service';
import { SphereService } from '../../../services/sphere.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DmsElementResource } from '../../../models/api/documents/dms-element-resource';

@Component({
  selector: 'app-attachments-uploader',
  templateUrl: './attachments-uploader.component.html',
  styleUrls: ['./attachments-uploader.component.scss']
})
export class AttachmentsUploaderComponent implements OnInit {

  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  uploader: FileUploader;
  hasDropZoneOver = false;
  multipleSelection = true;

  constructor(private uploaderService: UploaderService,
    private sphereService: SphereService,
    private ngxSmartModalService: NgxSmartModalService) {
  }

  ngOnInit(): void {
    this.uploader = this.uploaderService.uploader;
    this.uploader.onAfterAddingFile = () => this.onAfterAddingFile();
  }

  onAfterAddingFile() {
    if (!this.multipleSelection && this.uploader.queue.length > 1) {
      const latestFile = this.uploader.queue[this.uploader.queue.length - 1];
      this.uploader.queue = [];
      this.uploader.queue.push(latestFile);
    }
  }

  openModal() {
    this.ngxSmartModalService.getModal('attachmentModal').open();
  }

  submit() {
    this.ngxSmartModalService.getModal('attachmentModal').close();
    this.confirmed.emit();
  }

  fileOver($event: any) {
    this.hasDropZoneOver = $event;
  }

  cancel() {
    this.uploaderService.removePending();
    this.ngxSmartModalService.getModal('attachmentModal').close();
    this.canceled.emit();
  }

  onFileAdded(file: File[]) {
    // console.log('onFileAdded', file);
  }
}
